<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="515 430 300 300"
    style="enable-background:new 0 0 1190.6 1683.8;"
    xml:space="preserve"
  >
    <path
      :class="parte ==='cuello' || parte ==='otro' ? 'part_marked':''"
      d="M692.4,602.1c-34.5,51.7-62.4-2.9-62.4-2.9v0.2c0.3,8.2,0.6,16.2,1.3,24.3c0.3,3.8-0.3,6.9-1.7,9.5l0.3,0.1
		c0,0,29.7,10.2,61.6-0.2l0.2-0.1c-1.2-3.2-1-7-1.5-10.5c-0.9-7.1-0.1-14,2.3-20.6L692.4,602.1z"
    />
    <path
      :class="parte ==='cara' || parte ==='otro' ? 'part_marked':''"
      d="M692.5,601.9c0.7-1.9,1.5-3.7,2.4-5.6c0.4-0.8,0.8-2.2,1.3-2.2c5.7-0.2,5.3-4.2,6.1-8.3
		c1.6-7.5,2-14.5-0.9-21.9c-1.3-3.3,0.4-7.7,0.7-11.7c0.3-3.6,0.5-7.2,1-10.8c0.4-3.2,1-6.4,1.6-10c-0.7-0.2-1.9-0.5-3.4-0.9
		c0.9-3.3-3.2-7.6,2.5-10c-2.8-1.5-6-2.4-8-4.4c-4-4.1-9.2-5.8-14.1-8c-2.7-1.2-5.7-1.5-8.6-2.2c-9,0-18,0-27,0
		c-4.4,2.3-8.6,5-13.1,6.9c-4.4,1.8-6.9,4.1-8.2,9.2c-1.9,7.3-4.6,14.3-3.6,22.1c0.3,2.1,1.1,4.2,0.9,6.3c-0.5,4.8,1,9.2-1.6,14.5
		c-3.7,7.6-1.8,16.8,1.4,24.9c0.6,1.5,4.2,1.9,6.5,2.7c0.5-0.4,0.9-0.8,1.4-1.2c0.1,2.6,0.2,5.2,0.3,7.9l0,0.1
		C629.9,599.2,657.9,653.6,692.5,601.9L692.5,601.9z"
    />
  </svg>
</template>

<script>
export default {
  name: "Cara",
  props: ["parte"],
};
</script>
<style lang="sass">
path
	fill: var(--v-gris1-base) !important
	&.part_marked
		fill: var(--v-gris2-base) !important
		stroke: #272727
		stroke-width: 0.5
		stroke-miterlimit: 10
.selected_inner
	path
		fill: var(--v-gris1-base) !important
		&.part_marked
			fill: var(--v-primary-base) !important
			fill: #E4BB6D
			stroke: #000000
			stroke-width: 0.5
			stroke-miterlimit: 10

#body_selector .v-card:hover
	path.part_marked
		fill: #E4BB6D !important
</style>